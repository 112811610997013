//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.header {
}

.navigationWrapper {
  background: $white-color;
}

.logo {
  position: absolute;
  top: 0;
  z-index: 101;
}

.navigation {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  justify-content: space-between;
  padding: 25px 0;
  margin-left: 250px;
}

.generalNavigation {
  transition: max-height .2s linear;
  background: $main-color;
  padding: 14px;
}

.generalNavigationWrapper {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Depot New', sans-serif;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: repeat(3, max-content);
  justify-content: end;
  text-transform: uppercase;
   a {
     &:hover {
       color: $dark-blue-color;
     }
   }
}

.title {
  color: $black-blue-color;
  text-transform: uppercase;
  cursor: pointer;
}

.item {
  position: relative;
  display: grid;
  align-self: center;
  cursor: pointer;
  a {
    display: block;
    font-family: 'Depot New', sans-serif;
    padding: 10px 20px;
    &:hover {
      background: rgba(255, 255, 255, .1);
    }
  }

  &:hover {
    .menu {
      display: grid;
    }
    &:nth-child(1) {
      .menuWrapper {
        left: -280px;
      }
    }
    &:nth-child(2) {
      .menuWrapper {
        left: -451px;
      }
    }
    &:nth-child(3) {
      .menuWrapper {
        left: -618px;
      }
    }
    &:nth-child(4) {
      .menuWrapper {
        left: -834px;
      }
    }
  }
}

.mobileNavigation {
  transition: .3s linear;
  transition-delay: .19ms;
  height: 0;
  overflow: hidden;
}

.menuWrapper {
  position: absolute;
  z-index: 100;
  padding: 50px 0;
  left: 0;
  top: 20px;
}

.menu {
  display: none;
  grid-template-columns: repeat(3, 1fr);
  padding: 60px 30px 20px;
  width: 1140px;
  background: $dark-blue-color;
  color: $white-color;
}

.menuItem {
  font-size: 16px;
  text-transform: uppercase;
  margin: 10px 0;
}

@media (max-width: 1200px) {
  .button {
    width: initial;
  }
  .navigation {
    margin-left: 190px;
  }
}

@media (max-width: 925px) {
  .navigation {
    padding: 7px 0;
    margin-left: 170px;
  }
  .item {
    a {
      padding: 10px 5px;
      font-size: 15px;
    }
  }

  .logo {
    height: 155px;
  }
}

@media (max-width: 767px) {
  .generalNavigation {
    height: 15px;
    //max-height: 15px;
    .generalNavigationWrapper {
      display: none;
    }
  }

  .header {
    height: 100%;
    &.open {
      background: $main-color;
      .generalNavigation {
        height: 160px;
      }
      .generalNavigationWrapper {
        display: grid;
        justify-content: space-around;
        margin-top: 125px;
        margin-bottom: 30px;
      }

      .generalNavigation {
        padding-bottom: 0;
      }
      .mobileNavigation {
        height: 323px;
      }
    }
  }
  .logo {
    height: 120px;
  }

  .navigationWrapper {
    display: none;
  }
}
