//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


h1 {
  margin: 0;
  padding: 0;
}
html, body, .app {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100vh;
}
.popup {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  background: white;
  padding: 0px 50px 30px 50px;

  .massage {
    text-align: center;
    display: block;
    margin: 40px auto 40px auto;
    font-size: 25px;
    color: #4A4A4A;
    font-family:  San-Francisco, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
  .btn{
    margin: 0 auto;
    width: 200px;
    button{
      margin: 0 auto;
      font-family:  San-Francisco-Text, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      background: #f1efee;
      border: none;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 200px;
      height: 40px;
      cursor: pointer;
    }
  }

}
