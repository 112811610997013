//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.RadioInputGroup {
  display: flex;
  flex-wrap: wrap;
  .wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    .input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
      position: relative;
      background-color: #efeeee;
      border: 1px solid #d5d5d5;
      border-radius: 50%;
      margin-right: 10px;
      .checkmark {
        width: 4px;
        height: 4px;
        background-color: #9b9b9b;
        border-radius: 50%;
      }
    }
    .label {
      color: #9b9b9b;
      font-size: 12px;
    }
  }
}

.inline {
  display: flex;
  align-items: center;
  margin: 0 -10px;
  & > * {
    margin: 0 10px;
  }
}
