//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.navigation {
  background: $white-color;
  padding: 20px 43px 40px;
  display: none;
}

.arrow {
  transform: rotate(180deg);
  cursor: pointer;
}

.title {
  display: flex;
  align-items: center;
  color: $dark-blue-color;
  justify-content: space-between;
  font-size: 18px;
  padding: 15px 0;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Depot New', sans-serif;
  a {
    width: 100%;
  }
  img {
    width: 12px;
  }
}

.subcategory {
  font-family: 'Depot New', sans-serif;
  color: $dark-blue-color;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 6px 15px;
}

.subcategories {
  display: none;
}

.item {
  &.open {

    .subcategories {
      display: block;
    }

    .arrow {
      transform: rotate(0);
    }
  }
}

@media (max-width: 767px) {
  .navigation {
    display: block;
  }

  .buttonWrapper {
    display: grid;
    justify-content: center;
    margin-top: 30px;
    a {
      width: 250px;
    }
  }
}
