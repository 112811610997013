//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: absolute;
  right: 40px;
  height: 17px;
  top: 12px;
  cursor: pointer;
  display: none;
}

.menu {
  height: 3px;
  width: 25px;
  background: $white-color;
  position: relative;
  top: 8px;
  &:before, &:after {
    display: block;
    position: absolute;
    content: '';
    width: inherit;
    height: inherit;
    background: inherit;
    left: 0;
  }

  &:before {
    top: 6px;
  }

  &:after {
    bottom: 6px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    display: block;
  }
}
