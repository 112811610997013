//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.login-container{
  box-sizing: border-box;
  & * {
    box-sizing: border-box;
  }
  margin: 190px auto;
  width: 425px;
  background: #fff;
  padding: 40px 45px;
  font-family:  San-Francisco, -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  .login-title{
    font-size: 24px;
    color: #4A4A4A;
  }
  .not-found{
    color:red;
    font-size: 12px;
    line-height: 18px;
    margin-top: 3px;
  }
  .continue-btn{
    margin-top: 22px ;
    background: #f1efee;
    border: none;
    color: #292929;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
    width: 115px;
    height: 40px;
    cursor: pointer;
  }
  p{
    font-size: 12px;
    margin-top: 25px;
    margin-left: 15px;
  }
}

@media (max-width: 615px) {
  .login-container{
    margin: 20px auto;
    width: 360px;
  }
}