//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.DatePickerPopup {
  position: relative;
}

.calendar-date{
  position: absolute;
  min-height: 311px;
  width: 384px;
  box-shadow: 0 2px 23px #e4e6e9;
  
  .rdrMonth{
    padding: 0;
    margin: 0 auto;
    width: 355px;
  }

  .rdrPprevButton{
    position: relative;
    left: 300px;
    background: white;
    &:hover{
      background: white;
    }
    i{
      color: #000;
    }
  }

  .rdrMonthPicker select, .rdrYearPicker select{
    color: #6B7897;
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.59375px;
  }
  .rdrNextButton {
    position: relative;
    right: 15px;
    background: white;
    &:hover{
      background: white;
    }
    i{
      color: #000;
    }
  }
 .rdrMonthAndYearPickers{
   position:relative;
   right: 70px;
 }

  .rdrWeekDays span{
    font-size: 15.6548px;
    color: #B8BFC6!important;
  }

  .rdrDayNumber span{
    color:  #7C86A2 !important;
    font-size: 14.5366px;
    z-index: 2;
  }
  .rdrDayPassive span{
    opacity:0.5;
  }

  .rdrSelected{
    border-radius: 50% !important;
    height: 37px;
    width: 37px;
    left:7px !important;
    top:-1px;
    color:#D3D3D3 !important;
    z-index: 1;
  }
  .rdrDayHovered .rdrDayNumber:after {
    border-width: 1px !important;
    border-radius: 50% !important;
    height: 37px !important;
    width: 37px !important;
    position: absolute;
    left: 6.5px !important;
    top: -7px !important;
    color:#D3D3D3 !important;
  }
}