//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.links {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  grid-column-gap: 20px;
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  .link {
    transition: .2s linear;
    list-style: none;
    font-weight: 500;
    svg {
      transition: .2s linear;
      width: 20px;
      height: 20px;
      fill: $white-color;
    }
    path {
      transition: .2s linear;
      fill: $white-color;
    }

    &:hover {
      color: $light-color;
      svg, path {
        fill: $light-color;
      }
    }
  }
}

.generalInformation {
  padding: 40px 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: space-between;
}

.footer {
  color: $white-color;
}

.footerInformation {
  background: $main-color;
}

.hover-light {
  fill: $light-color;
  color: $light-color;
}

.information {
 font-size: 14px;
}

.highlighted {
  color: $dark-blue-color;
  transition: .2s linear;
  &:hover {
    color: $black-blue-color
  }
}

.content {
  margin-top: 30px;
}

.supportBy {
  background: $white-color;
}
.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, 25%);
  justify-content: space-between;
  align-items: center;
  grid-row-gap: 20px;
  padding: 30px 0;
  .item {
    justify-self: center;
    img {
      width: 160px;
    }
  }
}

@media (max-width: 767px) {
  .grid {
    grid-template-columns: 1fr;
  }
  .links {
    justify-self: center;
    justify-content: center;
  }
  .flexWrap {
    display: flex;
    flex-wrap: wrap;
  }
  .items {
    grid-template-columns: repeat(auto-fill, 180px);
  }
}

@media (max-width: 420px) {
  .items {
    justify-content: center;
  }
}
