//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.HomePage {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // height: 100%;
  // position: relative;
  min-width: 360px;
  //overflow: hidden;
  height: 100%;
  .collegeTitles {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 40px;
    font-size: 24px;
    color: #4a4a4a;
    font-family: San-Francisco, -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }
  .header {
    // width: 1256px;
    max-width: 1230px;
    padding: 0 20px;

    margin: 0 auto;
    position: relative;
    .header-upper {
      position: relative;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      .share-icons {
        position: absolute;
        left: 0;
        a {
          margin-right: 4px;
        }
      }
      .menu-icon {
        // display: none;
        position: absolute;
        right: 20px;
        cursor: pointer;
      }

      // .left{
      //   width: 38%;
      //   .share-icons{
      //     padding-top: 40px;
      //   a{
      //     margin-right: 10px;
      //   }
      //   }
      // }
      // .centre{
      //   width: 62%;
      //   padding-top: 40px;
      // }
    }
    .header-lower {
      // height: 50px;
      .main-menu {
        width: 100%;
        padding: 0;
        font-size: 14px;
        
        .section-orange {
          border-bottom: 3px solid #d8562a;
          ul li {
            border-bottom: 1px solid #d8562a;
          }
          div {
            color: #d8562a;
          }
        }
        .section_yellow {
          border-bottom: 3px solid #e8b300;
          ul li {
            border-bottom: 1px solid #e8b300;
          }
          div {
            color: #e8b300;
          }
          .sub-menu {
            left: 50%;
            transform: translate(-50%);
          }
        }
        .section_red {
          border-bottom: 3px solid #a22a38;
          ul li {
            border-bottom: 1px solid #a22a38;
          }
          div {
            color: #a22a38;
          }

          .sub-menu {
            right: 0;
          }
        }
        li {
          z-index: 10;
          position: relative;
          display: inline-block;
          width: 33.33%;
          text-align: left;
          cursor: pointer;

          // &:hover ul {
          //   display: block;
          // }
        }
      }

      .sub-menu {
        // display: none;
        position: absolute;
        background: white;
        width: 150%;
        margin-top: 3px;

        padding: 8px;
        li {
          width: 43%;
          vertical-align: top;
          padding: 8px 0;
          border-bottom: 1px solid black;
          img {
            position: absolute;
            top: 10px;
          }
          div {
            text-indent: 0;
            padding-left: 32px;
            font-weight: bold;
            padding-bottom: 2px;
            width: auto;
            font-family: "nexa_bold", arial;
          }
          p {
            display: inline-block;
            padding-left: 32px;
            padding-right: 12px;
            color: #5d272f;
            width: 90%;
            margin: 0;
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #5d272f;
    position: relative;
    .upper-footer {
      width: 1200px;
      padding: 20px;
      .item-block {
        vertical-align: top;
        margin: 5px;
        display: inline-block;
        position: relative;
        width: 190px;
        height: 190px;
        color: #fff;
        background: #703e43;
        font-family: "raleway", arial;
        &.item-block-long {
          width: 590px;
        }
        h2 {
          font-weight: 500;
          margin-left: 10px;
          margin-top: 10px;
          font-size: 18px;
        }
        h4 {
          margin-left: 10px;
          font-size: 14px;
          font-weight: normal;
        }
        .icon {
          position: absolute;
          left: 10px;
          bottom: 10px;
        }
        .link {
          position: absolute;
          display: inline-block;
          width: 190px;
          height: 190px;
        }
      }
    }
    .lower-footer {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .footer-left {
        color: white;
        padding: 15px;
        font-size: 14px;
        ul {
          list-style: none;
          padding: 0;
        }
        .title {
          color: #f07d00;
          margin-bottom: 10px;
        }
        .main-list {
          li {
            margin-right: 10px;
          }
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          display: flex;
          justify-content: space-between;
          .sub-list {
            li {
              margin-bottom: 5px;
            }
          }
        }
      }
      .footer-right {
        .logo {
          display: flex;
          padding: 25px;
          justify-content: space-between;
          flex-wrap: wrap;
          float: right;
          width: 350px;
        }
      }
    }
    .footer-text {
      margin-top: 2em;
      color: white;
      font-size: 0.9em;
      display: inline-block;
      padding: 0 10px;
    }
  }
}

@media (max-width: 1220px) {
  .HomePage {
    .footer {
      .upper-footer {
        width: 600px;
      }
      .lower-footer {
        width: 600px;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media (max-width: 615px) {
  .HomePage {
    .header {
      padding: 0;
      .header-lower {
        .main-menu {
          li {
            display: block;
            width: 100%;
            .menu-link {
              display: flex;
              align-items: flex-start;
              background-color: white;
              padding: 8px;
              font-size: 14px;
            }
            .sub-menu {
              width: 100%;
              position: relative;
              left: 50%;
              transform: translate(-50%);
              margin-top: 0;
              li {
                border-bottom: 1px solid black;
                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      .upper-footer {
        width: 320px;
        .item-block {
          width: 150px;
          height: 150px;
          &.item-block-long {
            width: 310px;
            h4 {
              font-size: 12px;
            }
          }
        }
      }
      .lower-footer {
        .footer-left {
          nav {
            .main-list {
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
