//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;



@font-face {
  font-family: San-Francisco;
  src: url('../assets/fonts/SF-Pro-Display-Regular.otf');
}

@font-face {
  font-family: San-Francisco-Text;
  src: url('../assets/fonts/SF-Pro-Text-Regular.otf');
}

@font-face {
  font-family: 'Depot New';
  src: local('Depot New Medium'), local('Depot-New-Medium'),
  url('../assets/fonts/DepotNew-Medium.woff2') format('woff2'),
  url('../assets/fonts/DepotNew-Medium.woff') format('woff'),
  url('../assets/fonts/DepotNew-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

body {
  font-family:  "Helvetica Neue", Helvetica,Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none;
}

input {
  padding: 0 10px;
}

a {
  color: inherit;
  text-decoration: none;
  transition: .2s linear;
  &:hover {
    text-decoration: none;
  }
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}
