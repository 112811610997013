//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.button {
  display: block;
  font-family: 'Depot New', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 20px 14px;
  background: $dark-blue-color;
  color: $white-color;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: all .4s ease;
  overflow: hidden;
  z-index: 1;
  box-shadow: none;
  text-align: center;
  width: 160px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -50px;
    width: 100px;
    height: 100px;
    background: $main-color;
    z-index: -1;
    transform: scale(0);
    border-radius: 50%;
    transition: all .5s ease;
    box-shadow: 0 0 15px 15px $main-color;
  }

  &:hover {
    background: $black-blue-color;

    &:after {
      transform: scale(6);
      background: #0078c2;
    }
  }
}
