//Colors
$main-color: #0053a0;
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$light-color: #10bbff;
$dark-blue-color: #009ddc;
$black-blue-color: #0078c2;

//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.title {
  display: inline-block;
  width: 320px;
  font-size: 24px;
  color: #4a4a4a;
  font-weight: 300;
}

.header {
  margin: 0;
  strong,
  p {
    font-size: $small-middle-size;
    line-height: 18px;
    color: #4a4a4a;
  }
}
.content {
  margin-top: 31px;
}

.container {
  padding: 52px;
}

.fields_grid {
  display: grid;
  //grid-template-columns: ;
}

.fields {
  display: grid;
  grid-row-gap: 20px;
  margin-top: 20px;

  .note {
    margin: 0;
    font-size: $small-middle-size;
  }
}

.wrapper_low {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin-top: 25px;
}

.wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 25px;
  margin-top: 25px;

}

.form {
  width: 1256px;
  margin: 65px auto 25px;
  background: #ffffff;
  position: relative;
}

.column {
  display: flex;
  p {
    font-size: $small-middle-size;
  }
}

.fields_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit_note {
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  color: #4a4a4a;
}

.invalid {
  color: red;
}

.captcha {
}

.center_items {
  display: grid;
  align-items: center;
}

.important_note {
  margin-top: 15px;
  .important {
    color: $red-color;
  }

  .note {
    font-size: $small-middle-size;
  }
}

.btn {
  width: 115px;
  height: 40px;
  margin-right: 40px;
  cursor: pointer!important;
  background: transparent;
}

.btn_saved {
  width: 115px;
  height: 40px;
  background-color: #fff;
  border: 1px solid $primary-color;
  color: $primary-color!important;
}

.disabled_btn {
  color: gray;
  cursor: default;
}

.btn_finish {
  width: 155px;
  height: 40px;
  background-color: $primary-color;
  color: $white-color!important;
  border: 0;
}

.selected_programme_title {
  padding: 6px 0 6px 0;
  font-size: 12px;
  line-height: 18px;
  color: #4a4a4a;
}

.selected_programme_item {
  font-size: 15px;
  line-height: 18px;
  color: #4a4a4a;
}

.actions {
 margin-top: 40px;
  width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1220px)  {
  .form {
    width: 685px;
  }

  .wrapper {
    grid-template-columns: 1fr;
    grid-row-gap: 25px;
    margin-top: 25px;
  }

  .studentForm {
    width: 615px;
  }

}

@media (max-width: 690px) {
  .form {
    width: 100%;
  }

  .container {
    padding: 30px 15px;
  }
}

@media (max-width: 615px) {
  .studentForm {
    width: 100%;
  }

  .column {
    flex-direction: column;
    align-items: center;
  }

  .row {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }

  .story {
    div:nth-child(2) {
        grid-template-columns: 1fr!important;
    }
  }

}

@media (max-width: 400px) {
  .title {
    width: 300px;
  }

  .fields_row {
    display: grid;
    grid-row-gap: 10px;
  }
}

@media (max-width: 350px) {
  .actions {
    display: grid;
    grid-row-gap: 10px;
    justify-content: center;
    text-align: center;

    button:nth-child(1) {
      margin: 0 auto!important;
    }
  }
}
